import { ColumnDefinition } from "@tager/admin-ui";

import { DealerLocationInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<DealerLocationInterface>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    style: { width: "50px", textAlign: "center" },
    headStyle: { width: "50px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 2,
    name: "API ID",
    field: "apiId",
    style: { width: "70px", textAlign: "center" },
    headStyle: { width: "70px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 3,
    name: "Название",
    field: "name",
  },
  {
    id: 4,
    name: "Дилер",
    field: "dealer",
  },
  {
    id: 5,
    name: "Действия",
    field: "actions",
    style: { width: "180px", textAlign: "center" },
    headStyle: { width: "180px", textAlign: "center", whiteSpace: "nowrap" },
  },
];
