import { ColumnDefinition } from "@tager/admin-ui";

import { DealerInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<DealerInterface>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    style: { width: "50px", textAlign: "center" },
    headStyle: { width: "50px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 2,
    name: "API ID",
    field: "apiId",
    style: { width: "70px", textAlign: "center" },
    headStyle: { width: "70px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 4,
    name: "Название",
    field: "name",
  },
  {
    id: 3,
    name: "Страница на сайте",
    field: "websiteUrl",
    type: "link",
    options: {
      disableCopyButton: true,
    },
  },
  {
    id: 4,
    name: "Рейтинг",
    field: "rating",
    style: { width: "70px", textAlign: "center" },
    headStyle: { width: "70px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 6,
    name: "",
    field: "relations",
    width: "350px",
  },
  {
    id: 5,
    name: "Действия",
    field: "actions",
    style: { width: "180px", textAlign: "center" },
    headStyle: { width: "180px", textAlign: "center", whiteSpace: "nowrap" },
  },
];
