import { request, ResponseBody } from "@tager/admin-services";

import {
  DealerLocationInterface,
  DealerLocationFullInterface,
  DealerLocationDivision,
} from "./typings";

export interface CountData {
  count: number;
}

export function getLocationsCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/dealers-locations/count" });
}

export function getLocations(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<DealerLocationInterface>>> {
  return request.get({ path: "/admin/dealers-locations", params });
}

export function getDealerLocation(
  id: string | number
): Promise<ResponseBody<DealerLocationFullInterface>> {
  return request.get({ path: `/admin/dealers-locations/${id}` });
}

export type DealerLocationCreatePayload = {
  dealer: number | null;
  apiId: number | null;
  name: string;
};

export function createDealerLocation(
  payload: DealerLocationCreatePayload
): Promise<ResponseBody<DealerLocationFullInterface>> {
  return request.post({
    path: `/admin/dealers-locations`,
    body: payload,
  });
}

export type DealerLocationUpdatePayload = {
  apiId: number | null;
  brandIds: number[];
  name: string;
  dealer: number | null;
  dealerCrm: number | null;
  image: string | null;
  website: string | null;
  phone: string | null;
  phoneStock: string | null;
  address: string | null;
  latitude: number | null;
  longitude: number | null;
  instagram: string | null;
  odnoklassniki: string | null;
  youtube: string | null;
  facebook: string | null;
  vkontakte: string | null;
  tiktok: string | null;
  telegram: string | null;
  viber: string | null;
  skype: string | null;
  divisions: Array<DealerLocationDivision>;
};

export function updateDealerLocation(
  id: number | string,
  payload: DealerLocationUpdatePayload
): Promise<ResponseBody<DealerLocationFullInterface>> {
  return request.put({
    path: `/admin/dealers-locations/${id}`,
    body: payload,
  });
}

export function deleteDealerLocation(
  id: number | string
): Promise<{ success: boolean }> {
  return request.delete({ path: `/admin/dealers-locations/${id}` });
}

export function moveDealerLocation(
  id: number | string,
  direction: "up" | "down"
): Promise<{ success: boolean }> {
  return request.post({
    path: `/admin/dealers-locations/${id}/move/${direction}`,
  });
}
